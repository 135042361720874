import get from 'lodash/get';

import store from '../setup/store';

const fleetIds = [
    '8ef04c71-8105-4bfb-a97c-a70db9d66975',
    '237c58e3-60b5-4442-a9ec-8641ea39325b',
    '0ff74f0d-c891-47ca-a7e6-9501be388fe7',
    '876f3819-6e1a-47cb-8e84-4d94f5055ee5',
    '728519fc-3337-4471-adf7-d4cb3249ff0f',
    '1f5c1128-8005-4716-98b9-721ed148b8a6',
    'd80732c1-e2ce-4955-9acf-a9df5e64ff37',
    '0e3174e5-113e-43d3-aecd-36bb39dfda67',
    'b472b5f1-3645-408e-bba6-1e2e5aa51e28',
    '97576b68-0788-402f-8e73-df247947b85b',
    '0bdfd716-3509-427a-9706-5554681ed756',
    'ef0dd23c-6c03-45b6-acb5-3bda8441a21a',
    '985941b3-b0ed-4c33-b181-72b64776b04b',
    '734c0a5e-fc49-4a05-a667-0100724f46fe',
    '3c88c5e8-bb2d-434c-882a-5578a8e7523c',
    'f8a883eb-5509-4cbd-aa19-cf9118216b3c',
    'dcc760bc-c3a6-4b32-8aa7-12f8e0a9d5c6',
    '06b15a2f-063e-4bee-bf9a-8b64cfb337bc',
    '824ddaa0-79bd-4a16-b946-46622a0239df',
    'de84d351-4410-4cc9-8de8-3c82b5ba969f',
    'b4696cdf-6077-4f5e-8199-3e30a199a64c',
    'bc0a86fc-b7df-4109-9ea2-36f8cf77ff8d',
    '3cfc7da0-32b8-4ed5-b048-9dfb89062928',
    '257b73aa-e1d0-41b9-ab64-69830848a66d',
    'db3d49ab-8184-4d09-8d07-36ff44a47415',
    'b54cb342-5076-4e76-9a7b-1c62b97e79c4',
    '67fa47f0-3137-4a28-b494-653e6610ba14',
    'b3086061-2348-410d-ae02-4394b256616f',
    '0e56bf54-0b15-47ba-a2cc-c8f63cd9f124',
    '284b3c1f-72c1-4669-a51b-c7ca9cc3c967',
    '536e99a5-440d-4bbb-ae2a-cfa3eadb6d11',
    'e8bfe2a1-c282-4d5b-a6f7-5c9783e5555b',
    'db97eb5b-657d-493c-977d-3a047450857a',
    '146ada3e-86db-4699-8c55-6b3d9bb79a27',
    'b95f36f2-1a79-4e20-b321-5736985d3c80',
    '15a1efab-bdbb-4ebb-a3e2-dd14af74f084',
    'a9167c34-5d29-4bb4-849a-737e915977e6',
    'd0d5f6e2-25ab-4172-b79a-9c1671c1168b',
    '30bc69e0-9bd3-4e9f-930a-9d6ccc182a1a',
    'b78a074c-f262-4a5d-b6b4-f220d3b5efcb',
    '302a455a-9ade-43cc-9e60-1dd4966bc883',
    '85522a2d-b7bc-4eae-9254-c47ba87c06c3',
    'c1a366f5-bc6e-4203-95cf-55af9242055d',
    '57cead91-0788-441f-9d28-082fc8cb852e',
    'c8e40117-552a-4c3d-80a2-91ac5440f74e',
    '215b175b-c003-41af-9ab7-a3fb7be2eaed',
    '3564583f-77dd-49d8-b014-b063f0c83f4d',
    'mockaccount',
];

const shouldEnableAnalysisPortal = () => fleetIds.includes(get(
    store.getState(),
    'login.userProfile.account',
    '',
));

export default shouldEnableAnalysisPortal;
