import { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/fp/isEmpty';
import isEqual from 'lodash/fp/isEqual';

import AssetTree from '@rio-cloud/rio-uikit/AssetTree';
import TreeCategory from '@rio-cloud/rio-uikit/TreeCategory';
import Tree from '@rio-cloud/rio-uikit/Tree';

import { FormattedMessage, injectIntl } from 'react-intl';

import {getSubTypeCounts, getTypeCounts, TreeOption,
    TreeSummary, TreeSummaryRow, TypeCounter } from '@rio-cloud/rio-uikit';

import { getRouteParamAsArray, saveStateToRoute, getRouteParam } from '../../features/utils/routing';

const assetTypes = ['truck', 'bus', 'van', 'trailer'];
const fuelTypes = ['fuel-diesel', 'fuel-electric', 'fuel-gas', 'fuel-hydrogen'];
const getFuelTypeCounts = getSubTypeCounts;

const getNotShownAssets = (
    selected,
    assets,
) => selected.filter(assetId => !assets.find(asset => asset.id === assetId));

const getNotShownGroups = (
    selected,
    groupIds,
) => selected.filter(groupId => !groupIds.find(id => id === groupId));

const toggleListItem = (list, item) => {
    const set = new Set(list);
    if (set.has(item)) {
        set.delete(item);
    } else {
        set.add(item);
    }
    return [...set];
};
class AssetTreeComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentCategoryId: getRouteParam('currentCategoryId') || 'AssetTree-vehicles',
            selectedVehicles: getRouteParamAsArray('selectedVehicles') || [],
            selectedVehicleGroupIds: getRouteParamAsArray('selectedVehicleGroupIds') || [],
            expandedVehicleGroups: getRouteParamAsArray('expandedVehicleGroups') || [],
            selectedDrivers: getRouteParamAsArray('selectedDrivers') || [],
            selectedDriverGroupIds: getRouteParamAsArray('selectedDriverGroupIds') || [],
            expandedDriverGroups: getRouteParamAsArray('expandedDriverGroups') || [],
            tags: {},
            fuelTypeToggleSelected: false,
            assetCounters: getTypeCounts(this.props.vehicles),
            fuelCounters: getFuelTypeCounts(this.props.vehicles),
            activeAssetTypeFilter: [],
            activeFuelTypeFilter: [],
            filteredItems: this.props.vehicles,
            showEmptyGroups: false,
            showGroups: true,
            vehicleGroups: [],
            driverGroups: [],
        };
        this.handleSelectDriver = this.handleSelectDriver.bind(this);
        this.handleExpandDriverGroups = this.handleExpandDriverGroups.bind(this);

        this.handleVehicleSelection = this.handleVehicleSelection.bind(this);
        this.handleExpandVehicleGroups = this.handleExpandVehicleGroups.bind(this);

        this.onCategoryChange = this.onCategoryChange.bind(this);
        this.onToggleTree = this.onToggleTree.bind(this);
        this.handleFuelTypeToggle = this.handleFuelTypeToggle.bind(this);
        this.handleAssetTypeFilterChange = this.handleAssetTypeFilterChange.bind(this);
        this.handleFuelTypeFilterChange = this.handleFuelTypeFilterChange.bind(this);
        this.handleShowEmptyGroupsToggle = this.handleShowEmptyGroupsToggle.bind(this);
        this.handleShowGroupsToggle = this.handleShowGroupsToggle.bind(this);

    }

    handleAssetTypeFilterChange(assetTypeFilter) {
        const vehicles = this.getVehicles(this.state.fuelTypeToggleSelected);
        this.setState(
            {
                activeAssetTypeFilter: toggleListItem(
                    this.state.activeAssetTypeFilter,
                    assetTypeFilter,
                ),
                filteredItems: vehicles,
            },
            () => {
                if (!isEmpty(this.state.activeAssetTypeFilter) && !isEmpty(this.state.activeFuelTypeFilter)) {
                    this.setState({
                        filteredItems: this.state.filteredItems
                            .filter(item => this.state.activeAssetTypeFilter.includes(item.type) &&
                                this.state.activeFuelTypeFilter.includes(item.subType)),
                    });
                } else if (!isEmpty(this.state.activeAssetTypeFilter)) {
                    this.setState({
                        filteredItems: this.state.filteredItems
                            .filter(item => this.state.activeAssetTypeFilter.includes(item.type)),
                    });
                } else if (!isEmpty(this.state.activeFuelTypeFilter)) {
                    this.setState({
                        filteredItems: this.state.filteredItems.filter(item => item.subType ?
                            this.state.activeFuelTypeFilter.includes(item.subType) :
                            item),
                    });
                }
            },
        );

    }

    handleFuelTypeFilterChange(fuelTypeFilter) {
        const vehicles = this.getVehicles(this.state.fuelTypeToggleSelected);

        this.setState(
            {
                activeFuelTypeFilter: toggleListItem(
                    this.state.activeFuelTypeFilter,
                    fuelTypeFilter,
                ),
                filteredItems: vehicles,
            },
            () => {
                // eslint-disable-next-line no-negated-condition
                if (!isEmpty(this.state.activeAssetTypeFilter) && !isEmpty(this.state.activeFuelTypeFilter)) {
                    this.setState({
                        filteredItems: this.state.filteredItems
                            .filter(item => this.state.activeAssetTypeFilter.includes(item.type) &&
                            this.state.activeFuelTypeFilter.includes(item.subType)),
                    });
                } else if (!isEmpty(this.state.activeAssetTypeFilter)) {
                    this.setState({
                        filteredItems: this.state.filteredItems
                            .filter(item => this.state.activeAssetTypeFilter.includes(item.type)),
                    });
                } else if (!isEmpty(this.state.activeFuelTypeFilter)) {
                    this.setState({
                        filteredItems: this.state.filteredItems.filter(item => item.subType ?
                            this.state.activeFuelTypeFilter.includes(item.subType) :
                            item),
                    });
                }
            },
        );
    }

    handleFuelTypeToggle() {
        this.setState(
            { fuelTypeToggleSelected: !this.state.fuelTypeToggleSelected, activeFuelTypeFilter: []},
            () => {
                const vehicles = this.getVehicles(this.state.fuelTypeToggleSelected);
                this.setState({
                    filteredItems: vehicles,
                });
                if (!isEmpty(this.state.activeAssetTypeFilter)) {
                    this.setState({
                        filteredItems: vehicles.filter(item => this.state.activeAssetTypeFilter.includes(item.type)),
                    });
                }
            },
        );
    }

    handleShowEmptyGroupsToggle() {
        this.setState({ showEmptyGroups: !this.state.showEmptyGroups});
    }

    handleShowGroupsToggle() {
        this.setState({ showGroups: !this.state.showGroups});
    }

    getVehicles(showFuelType) {
        return this.props.vehicles.map(vehicle => ({
            ...vehicle,
            // eslint-disable-next-line no-undefined
            subType: showFuelType ? vehicle.subType : undefined,
        }));
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !isEqual(
            nextProps,
            this.props,
        ) || !isEqual(
            nextState,
            this.state,
        );
    }

    // eslint-disable-next-line max-statements
    componentDidMount() {
        const emptyVehicleGroups = Object.values(this.props.tags)
            .filter(tag => !this.props.vehicleGroupIds.includes(tag.id) &&
         tag.id !== 'unassignedVehicles' &&
         tag.id !== 'unassignedDrivers');
        const vehicleGroups = [
            ...emptyVehicleGroups, ...(this.props.vehicleGroupIds || [])
                .map(id => id === 'unassignedVehicles' ?
                    {
                        id: 'unassignedVehicles',
                        name: this.props.intl.formatMessage({
                            id: 'ungrouped',
                            defaultMessage: 'Ungrouped',
                        }),
                        position: 'last',
                    } :
                    this.props.tags[id] || { id, name: id }),
        ];
        const emptyDriverGroups = Object.values(this.props.tags)
            .filter(tag => !this.props.driverGroupIds.includes(tag.id) &&
         tag.id !== 'unassignedVehicles' &&
         tag.id !== 'unassignedDrivers');
        const driverGroups = [
            ...emptyDriverGroups, ...(this.props.driverGroupIds || [])
                .map(id => id === 'unassignedVehicles' ?
                    {
                        id: 'unassignedVehicles',
                        name: this.props.intl.formatMessage({
                            id: 'ungrouped',
                            defaultMessage: 'Ungrouped',
                        }),
                        position: 'last',
                    } :
                    this.props.tags[id] || { id, name: id }),
        ];
        this.setState({vehicleGroups, driverGroups});
        const vehicles = this.getVehicles(this.state.fuelTypeToggleSelected);
        this.setState({
            filteredItems: vehicles,
        });
        if (!isEmpty(this.state.selectedVehicles) || !isEmpty(this.state.selectedVehicleGroupIds)) {
            if (this.props.rawVehicles.length) {
                const notShownSelectedVehicles = getNotShownAssets(
                    this.state.selectedVehicles,
                    this.props.rawVehicles,
                );
                const notShownSelectedVehicleGroups =
                    getNotShownGroups(
                        this.state.selectedVehicleGroupIds,
                        this.props.vehicleGroupIds,
                    );

                this.handleVehicleSelection({
                    items: this.state.selectedVehicles
                        .filter(vehicleId => !notShownSelectedVehicles.includes(vehicleId)),
                    groups: this.state.selectedVehicleGroupIds
                        .filter(groupId => !notShownSelectedVehicleGroups.includes(groupId)),
                });
            }
        }

        if (!isEmpty(this.state.selectedDrivers) || !isEmpty(this.state.selectedDriverGroupIds)) {
            if (this.props.rawDrivers.length) {
                const notShownSelectedDriver =
                    getNotShownAssets(
                        this.state.selectedDrivers,
                        this.props.rawDrivers,
                    );
                const notShownSelectedDriverGroups =
                    getNotShownGroups(
                        this.state.selectedDriverGroupIds,
                        this.props.driverGroupIds,
                    );

                this.handleSelectDriver({
                    items: this.state.selectedDrivers
                        .filter(driverId => !notShownSelectedDriver.includes(driverId)),
                    groups: this.state.selectedDriverGroupIds
                        .filter(groupId => !notShownSelectedDriverGroups.includes(groupId)),
                });

            }
        }

        this.props.setCurrentCategory(this.state.currentCategoryId);
    }

    sendSelectedVehicles(selectedVehicles, selectedVehicleGroupIds) {
        const newselectedVehicles = new Set();
        selectedVehicles.forEach(vehicleId => newselectedVehicles.add(vehicleId));

        selectedVehicleGroupIds.forEach(groupId => {
            if (this.props.vehicleIdsByGroup[groupId]) {
                this.props.vehicleIdsByGroup[groupId].forEach(vehicleId => newselectedVehicles.add(vehicleId));
            }
        });

        saveStateToRoute({
            selectedVehicles: Array.from(newselectedVehicles),
            selectedVehicleGroupIds,
            selectedDrivers: [],
            selectedDriverGroupIds: [],
        });

        this.props.setSelectedVehicles(Array.from(newselectedVehicles));
    }

    handleVehicleSelection({ items, groups }) {
        this.setState({
            selectedVehicles: items,
            selectedVehicleGroupIds: groups,
            selectedDrivers: [],
            selectedDriverGroupIds: [],
        });

        this.sendSelectedVehicles(
            items,
            groups,
        );
    }

    handleExpandVehicleGroups(expandedVehicleGroups) {

        this.setState({
            expandedVehicleGroups,
        });
    }

    handleExpandDriverGroups(expandedDriverGroups) {

        this.setState({
            expandedDriverGroups,
        });
    }

    handleSelectDriver({ items, groups }) {
        const newSelectedDrivers = new Set();
        items.forEach(driverId => newSelectedDrivers.add(driverId));

        groups.forEach(groupId => {
            if (this.props.driverIdsByGroup[groupId]) {
                this.props.driverIdsByGroup[groupId].forEach(driverId => newSelectedDrivers.add(driverId));
            }
        });

        saveStateToRoute({
            selectedVehicles: [],
            selectedVehicleGroupIds: [],
            selectedDrivers: Array.from(newSelectedDrivers),
            selectedDriverGroupIds: groups,
        });

        this.setState({
            selectedVehicles: [],
            selectedVehicleGroupIds: [],
            selectedDrivers: items,
            selectedDriverGroupIds: groups,
        });

        this.props.setSelectedDrivers(Array.from(newSelectedDrivers));
    }

    onToggleTree(isOpen) {
        this.props.setAssetTreeState(isOpen);
    }

    onCategoryChange(newCategory) {
        saveStateToRoute({
            currentCategoryId: newCategory,
        });

        this.props.setCurrentCategory(newCategory);
        this.setState({ currentCategoryId: newCategory });
    }

    getVehicleTreeCategory() {
        return (
            <TreeCategory
                key={'AssetTree-vehicles'}
                id={'AssetTree-vehicles'}
                label={<FormattedMessage id={'vehicles'} defaultMessage={'Vehicles'} />}
                icon={'rioglyph-truck'}
                hasSelection={
                    !isEmpty(this.state.selectedVehicles) ||
                    !isEmpty(this.state.selectedVehicleGroupIds)
                }
            >
                <Tree
                    groups={
                        this.state.showGroups ?
                            this.state.vehicleGroups :
                            []
                    }
                    items={this.state.filteredItems}
                    expandedGroups={this.state.expandedVehicleGroups}
                    onExpandGroupsChange={this.handleExpandVehicleGroups}
                    selectedGroups={this.state.selectedVehicleGroupIds}
                    selectedItems={this.state.selectedVehicles}
                    onSelectionChange={this.handleVehicleSelection}
                    searchPlaceholder={'Enter filter here'}
                    showEmptyGroups={this.state.showEmptyGroups}
                    treeOptions={[
                        <TreeOption
                            key={'emptyGroupsToggle'}
                            label={<FormattedMessage
                                id={'asset-tree.option.showEmptyGroups.label'}
                                defaultMessage={'Show empty groups'}/>}
                            isChecked={this.state.showEmptyGroups}
                            onChange={this.handleShowEmptyGroupsToggle}
                        />,
                        <TreeOption
                            key={'assetGroupsToggle'}
                            label={<FormattedMessage
                                id={'asset-tree.option.showGroups.label'}
                                defaultMessage={'Show groups'}/>}
                            isChecked={this.state.showGroups}
                            onChange={this.handleShowGroupsToggle}
                        />,
                        <div key={'fuelType'}>
                            <TreeOption
                                key={'fuelTypeToggle'}
                                label= {<FormattedMessage
                                    id={'asset-tree.option.showFuelTypes.label'}
                                    defaultMessage={'Show fuel type'}/>}
                                isChecked={this.state.fuelTypeToggleSelected}
                                onChange={this.handleFuelTypeToggle}
                            />

                            <div
                                className={'text-size-11 text-color-dark' +
                                    ' padding-right-10 margin-right-25 margin-top-5'}>
                                <FormattedMessage
                                    id={'asset-tree.option.showFuelTypes.description'}
                                    // eslint-disable-next-line max-len
                                    defaultMessage={'Ensure you have defined your vehicle\'s fuel type in the asset administration settings'} />
                                <a
                                    href={'https://administration.assets.rio.cloud/#/assets'}
                                    target={'_blank'}
                                    rel={'noreferrer'}
                                    className={'text-decoration-none margin-left-5'}
                                >
                                    <span className={'rioglyph rioglyph-new-window'} />
                                </a>
                            </div>
                        </div>,
                    ]}
                    summary={
                        <TreeSummary>
                            <TreeSummaryRow>
                                {assetTypes.map(assetType => (
                                    <TypeCounter
                                        key={assetType}
                                        type={assetType}
                                        icon={`${assetType}-baseline`}
                                        value={this.state.assetCounters[assetType]}
                                        isActive={this.state.activeAssetTypeFilter.includes(assetType)}
                                        hasFilter={!isEmpty(this.state.activeAssetTypeFilter)}
                                        onClick={this.handleAssetTypeFilterChange}
                                        enableActivity
                                        hideOnZero
                                    />
                                ))}
                            </TreeSummaryRow>
                            <TreeSummaryRow>
                                {this.state.fuelTypeToggleSelected &&
                                    fuelTypes.map(fuelType => (
                                        <TypeCounter
                                            key={fuelType}
                                            type={fuelType}
                                            icon={fuelType}
                                            value={this.state.fuelCounters[fuelType]}
                                            isActive={this.state.activeFuelTypeFilter.includes(fuelType)}
                                            hasFilter={!isEmpty(this.state.activeFuelTypeFilter)}
                                            onClick={this.handleFuelTypeFilterChange}
                                            enableActivity
                                            hideOnZero
                                        />
                                    ))}
                            </TreeSummaryRow>
                        </TreeSummary>
                    }
                />
            </TreeCategory>
        );
    }

    getDriverTreeCategory() {
        return (
            <TreeCategory
                key={'AssetTree-drivers'}
                id={'AssetTree-drivers'}
                label={<FormattedMessage id={'drivers'} defaultMessage={'Drivers'} />}
                icon={'rioglyph-driver'}
                hasSelection={
                    !isEmpty(this.state.selectedDrivers) ||
                    !isEmpty(this.state.selectedDriverGroupIds)
                }
            >
                <Tree
                    items={this.props.drivers}
                    selectedItems={this.state.selectedDrivers}
                    onSelectionChange={this.handleSelectDriver}
                    searchPlaceholder={'Enter filter here'}
                    groups={
                        this.state.showGroups ?
                            this.state.driverGroups :
                            []
                    }
                    expandedGroups={this.state.expandedDriverGroups}
                    onExpandGroupsChange={this.handleExpandDriverGroups}
                    selectedGroups={this.state.selectedDriverGroupIds}
                    showEmptyGroups={this.state.showEmptyGroups}
                    treeOptions={[
                        <TreeOption
                            key={'emptyGroupsToggle'}
                            label={<FormattedMessage
                                id={'asset-tree.option.showEmptyGroups.label'}
                                defaultMessage={'Show empty groups'}/>}
                            isChecked={this.state.showEmptyGroups}
                            onChange={this.handleShowEmptyGroupsToggle}
                        />,
                        <TreeOption
                            key={'assetGroupsToggle'}
                            label={<FormattedMessage
                                id={'asset-tree.option.showGroups.label'}
                                defaultMessage={'Show groups'}/>}
                            isChecked={this.state.showGroups}
                            onChange={this.handleShowGroupsToggle}
                        />,
                    ]}
                />
            </TreeCategory>
        );
    }

    render() {
        return (
            <AssetTree
                resizable
                minWidth={300}
                maxWidth={450}
                isOpen={this.props.isAssetTreeOpen}
                onToggleTree={this.onToggleTree}
                currentCategoryId={this.props.currentCategoryId}
                onCategoryChange={this.onCategoryChange}
            >
                {this.getVehicleTreeCategory()}
                {this.getDriverTreeCategory()}
            </AssetTree>
        );
    }
}

AssetTreeComponent.defaultProps = {
    tags: {},
    vehicleGroupIds: [],
    vehicles: [],
    drivers: [],
    vehicleIdsByGroup: {},
};

AssetTreeComponent.propTypes = {
    intl: PropTypes.object.isRequired,
    tags: PropTypes.object,
    vehicleGroupIds: PropTypes.array,
    vehicles: PropTypes.array,
    rawDrivers: PropTypes.array,
    rawVehicles: PropTypes.array,
    vehicleIdsByGroup: PropTypes.object,
    drivers: PropTypes.array,
    currentCategoryId: PropTypes.string,
    driverGroupIds: PropTypes.array,
    driverIdsByGroup: PropTypes.object,

    successfulFetchAssets: PropTypes.func,
    successfulFetchDrivers: PropTypes.func,
    successfulFetchTags: PropTypes.func,

    setSelectedVehicles: PropTypes.func,
    setSelectedDrivers: PropTypes.func,

    setCurrentCategory: PropTypes.func,
    isAssetTreeOpen: PropTypes.bool,
    setAssetTreeState: PropTypes.func,
};

export default injectIntl(AssetTreeComponent);
